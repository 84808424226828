<template>
  <div class="login-body">
    <div class="login-container">
      <div class="login-left">
      </div>
      <div class="login-right">
        <div class="head">
          <!--        <img class="logo" src="https://s.weituibao.com/1582958061265/mlogo.png" />-->
          <div class="name">
            <div class="title">Trade Lab</div>
            <div class="tips">- <span style="color:#3B6EFB;">证券投资基金虚拟仿真</span>平台 -</div>
          </div>
        </div>
        <el-form  :rules="rules" :model="ruleForm"  ref="registerForm" class="login-form" autocomplete="off">
          <!-- <el-form-item label="用户名" prop="userName">
            <el-input type="text" placeholder="请输入用户名" style="width: 200px;" size="mini"
                      v-model.trim="ruleForm.userName" autocomplete="off"></el-input>
          </el-form-item> -->
          <el-form-item label="" prop="schoolName" style="margin-bottom: 30px" class="schoolName">
            <el-select v-model="ruleForm.domainName" style="width: 360px;" placeholder="请选择学校">
              <el-option
              v-for="item in options"
                :key="item.schoolDomain"
                :label="item.schoolName"
                :value="item.schoolDomain">
              </el-option>
            </el-select>
            <!-- <el-input type="text" placeholder="schoolName" v-model.trim="ruleForm.schoolName" autocomplete="off"></el-input> -->
          </el-form-item>
          <el-form-item label="" prop="name" style="margin-bottom: 30px;">
            <el-input type="text" placeholder="请输入名称"  class="form-input"
                      v-model.trim="ruleForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="userName" style="margin-bottom: 30px;">
            <el-input type="number" placeholder="请输入学号"  class="form-input"
                      v-model.trim="ruleForm.userName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password" style="margin-bottom: 30px;">
            <el-input type="password" placeholder="请输入密码"  class="form-input"
                      v-model.trim="ruleForm.password" autocomplete="off" @keydown.enter="submitForm"></el-input>
          </el-form-item>
          
          <el-form-item label="" prop="phone">
            <el-input type="text" placeholder="请输入手机号(非必填)"  class="form-input"
                      v-model.trim="ruleForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <el-form-item label="" prop="code">
            <el-input type="text" placeholder="验证码"  style="width: 100px;" size="mini"
                      v-model.trim="ruleForm.code" autocomplete="off"></el-input>
            <el-button style="margin-left: 5px" size="mini"
                      type="primary" @click="getPhoneCode" :loading="loadingCode">获取验证码</el-button>
          </el-form-item> -->
          <el-form-item>
            <!--          <div style="color: #333">登录表示您已同意<a>《服务条款》</a></div>-->
        <!-- <el-button style="width: 100px;margin-top: 20px;float: right; margin-left:-20px" size="mini"
                  type="primary" @click="backLogin" :loading="loading">返回</el-button> -->
            
            <el-button class="logon-button"
                      type="primary" @click="submitForm" :loading="loading">注册</el-button>
                      <div style="color: #767676;margin-top: 15px;">已有账号？ <span @click="backLogin" style="color: #577AF3;cursor:pointer;">立即登录</span></div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
import {onMounted, reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getSchools} from "@/views/system-manage/school-manage/schoolApi.js";
// import { localSet } from '@/utils'
import { ElMessage } from 'element-plus';

export default {
  name: 'register',
  setup() {
    const router = useRouter()
    const registerForm = ref(null)
    const state = reactive({
      ruleForm: {
        domainName:'',
        name: '',
        password: '',
        userName: '',
        phone: '',
        isJudge:false
      },
      loading:false,
      loadingCode:false,
      rules: {
        domainName: [
          { required: 'true', message: '学校不能为空', trigger: 'blur' }
        ],
        // userName: [
        //   { required: 'true', message: '账户不能为空', trigger: 'blur' }
        // ],
        name: [
          { required: 'true', message: '名称不能为空', trigger: 'blur' }
        ],
        userName: [
          { required: 'true', message: '学号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: 'true', message: '密码不能为空', trigger: 'blur' },
		  
		      { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/,
            message: "密码需为8~20位且需包含数字、大小写字母、特殊字符两种及以上",}
        ],
        phone: [
          { required: false,message: '请输入手机号码',trigger: 'blur'},
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确手机号码",}
        ],
        // code: [
        //   { required: 'true', message: '验证码不能为空', trigger: 'blur' }
        // ],
      },
      options: [],
    })
    onMounted(() => {
      getSchoolNames()
    })
    let getSchoolNames =() => {
      let parame = {
        schoolName:'',
        pageSize: 10000,
        pageIndex: 1,
      };
      getSchools(parame).then((res) => {
        if (res.data.code === "200") {
          let newRes = res.data.data;
          state.options = newRes.schoolPageInfo.list;
       }
      });
    }
    const submitForm = async () => {
      registerForm.value.validate((valid) => {
        state.loading = true
        if (valid) {
          let newParame = Object.assign(state.ruleForm,)
          axios.post('/tradelab/sysUser/addSysUser', newParame).then(res => {
            if (res.data.code === '200'){
              let nesRow = res.data.data
              ElMessage.success(res.data.msg)
              router.push('/login')
              state.loading = false
            }else {
              state.loading = false
              ElMessage.error(res.data.msg)
            }
          })
        } else {
          state.loading = false
          return false;
        }
      })
    }
	
	const backLogin = () =>{
		router.push('/login')
	}
    const resetForm = () => {registerForm.value.resetFields();}
    const getPhoneCode = () => {
      if (!state.ruleForm.phone)return ElMessage.warning('请输入手机号码')
      state.loadingCode = true
      axios.post('/tradelab/sysUser/getVerificationCode',{
        phone: state.ruleForm.phone
      }).then(res => {
        if (res.data.code === '200'){
          let nesRow = res.data.data
          setTimeout(()=>{
            state.ruleForm.code = nesRow
            state.loadingCode = false
          },2000)
        }else {
          state.loadingCode = false
          ElMessage.error(res.data.msg)
        }
      })
    }
    return {
      ...toRefs(state),
      registerForm,
      submitForm,
      getPhoneCode,
      resetForm,
	  backLogin
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>
<style scoped>
.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #EEEFF5;
  background-size: 100%;
}
.login-container {
  min-width: 1200px;
  height: 700px;
  background: #FFFFFF;
  box-shadow: 0px 2px 140px 15px rgba(107,133,228,0.15);
  border-radius: 24px;
  overflow: hidden;
}

.login-left{
    width: 600px;
    height: 700px;
    background: url('../../assets/login-img.png') no-repeat ;
    background-size: contain;
    float: left;
  }
.login-right{
  width: 600px;
  text-align: center;
  float: right;
}
.head {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  padding: 50px 0 10px 0;
}
.head img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.head .title {
  letter-spacing: 2px;
  font-size: 46px;
  color: #222222;
  font-weight: 600;
}
.head .tips {
  margin-top: 20px;
  font-size: 18px;
  color: #666666;
}
.login-form {
  width: 360px;
  margin: 30px auto;
}
.form-input{
  width: 360px;
}
.logon-button{
  margin-top: 30px;
  width: 360px;
  background-color: #3B6EFB !important;
  border: none !important;
  height: 54px;;
  border-radius: 8px !important;
}
.logon-button:hover{
   opacity: 0.5;
  }
.el-select-dropdown__item{
  width: 80%;
  margin-left: 10%;
  border-bottom: #F4F6FA 2px solid;
  padding:0 !important;
}
.el-select-dropdown__item.hover{
  color: #658DFC !important;
  background-color:#FFFFFF !important;
  font-weight: 500 !important;
}
.el-select-dropdown__item:hover{
  color: #658DFC !important;
  background-color:#FFFFFF !important;
  font-weight: 500 !important;
}
</style>
<style>
.el-form--label-top .el-form-item__label {
  padding: 0;
}
.login-form .el-form-item {
  margin-bottom: 12px;
}
.schoolName .el-input__inner{
    height: 40px;
    line-height: 28px;
}
input:-webkit-autofill,
textarea:-webkit-autofill, 
select:-webkit-autofill {
        background-color:transparent;
        background-image: none;
         transition: background-color 50000s ease-in-out 0s; /*背景色透明  生效时长  过渡效果  启用时延迟的时间*/
}
input:-webkit-autofill::first-line{
  font-size: 16px;
}
input:-webkit-autofill{

/* -webkit-box-shadow: 0 0 0 400px #E8ECED inset; */

-webkit-text-fill-color: #03032C;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;

}
</style>
